/** @format */

import React, { useState, useRef, useEffect } from 'react'
import * as Styles from './style'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import close from '../../../assets/images/close.png'
import { InputGroup } from '../../../shared/InputGroup/InputGroup'
import { SelectGroup } from '../../../shared/SelectGroup/SelectGroup'

import Select from 'react-select'
import {
  editSingleCluster,
  resetErrorinEditCluster,
  resetMessageinEditCluster,
} from '../../../reduxLogic/reducers/clusters/editSingleClusterSlice'

import { getAllProjects } from '../../../reduxLogic/reducers/projects/getAllProjectSlice'
import { getAllClusters } from '../../../reduxLogic/reducers/clusters/getAllClustersSlice'

// Update initial values to include new fields
const initialValues = {
  project: '',
  clusterName: '',
  status: '',
  state: '',
  district: '',
  comments: '',
}

const projectSchema = Yup.object().shape({})

const EditProjectForm = props => {
  const { error, message } = useSelector(state => state.editProject)
  const { openEditModal, setOpenEditModal, idToEdit, singleRow, data } = props

  const [singleClusterData, setSingleClusterData] = useState(singleRow || {})
  const [isEditing, setIsEditing] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState(singleClusterData.status)

  const formikRef = useRef(null)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllProjects())
  }, [idToEdit])

  const { projectData } = useSelector(state => state.allProjects || {})

  useEffect(() => {
    if (singleClusterData) {
      formikRef.current.setValues(singleClusterData)
    }
  }, [singleClusterData])

  const handleStateChange = value => {
    setSelectedStatus(value)
  }

  const handleClose = () => {
    // dispatch(resetSingleClusterData());
    setOpenEditModal(false)
  }

  const statusOptions = ['active', 'inactive']
  const handleSubmit = values => {
    setIsEditing(true)
    const clusterData = {
      ...values,
    }
    let editId = singleClusterData?._id

    console.log('Edit ID: ', editId)

    dispatch(editSingleCluster({ editId, clusterData }))
      .then(() => dispatch(getAllClusters()))
      .then(() => setOpenEditModal(false))
      .then(() => setIsEditing(false))
      .catch(err => toast.error(`${err}`))
  }

  useEffect(() => {
    if (error) {
      toast.error(`${error}`)
      dispatch(resetErrorinEditCluster())
      dispatch(resetMessageinEditCluster())
    }

    if (!error && message) {
      toast.success('Project was updated successfully')
      dispatch(resetMessageinEditCluster())
    }
  }, [error, dispatch, message])

  return (
    <Styles.ModalWrapper>
      <Styles.ModalContent>
        {singleClusterData ? (
          <Formik
            initialValues={singleClusterData || initialValues}
            onSubmit={handleSubmit}
            validationSchema={projectSchema}
            innerRef={formikRef}
            validateOnMount
          >
            {formik => (
              <Styles.FormWithCloseButton>
                <Styles.CloseButtonDiv>
                  <h3>Edit Project Form</h3>
                  <Styles.FormCloseButton onClick={handleClose}>
                    <img src={close} alt="Close" />
                  </Styles.FormCloseButton>
                </Styles.CloseButtonDiv>

                <Styles.MainSupplierFormDiv>
                  {/* <Select
                    isSearchable
                    options={projectData?.map((project) => ({
                      value: project._id,
                      label: project.name,
                    }))}
                    onChange={(selectedOption) => {
                      formik.setFieldValue("project", selectedOption.value);
                    }}
                    selectedOption={projectData?.filter(
                      (project) => project._id === singleClusterData.projectId
                    )}
                  /> */}
                  <Styles.RowFlex>
                    <SelectGroup
                      PlaceholderText="Status"
                      LabelText="Select status"
                      name="status"
                      onChange={e => {
                        handleStateChange(e.target.value)
                        formik.handleChange(e)
                      }}
                      value={selectedStatus}
                      options={statusOptions}
                      star="*"
                    />
                  </Styles.RowFlex>
                </Styles.MainSupplierFormDiv>

                <Styles.CreateSupplierButton type="submit">
                  {!isEditing ? 'Edit' : '...editing'}
                </Styles.CreateSupplierButton>
              </Styles.FormWithCloseButton>
            )}
          </Formik>
        ) : (
          <h4>
            There was an error fetching data for Project with ID {idToEdit}
          </h4>
        )}
      </Styles.ModalContent>
    </Styles.ModalWrapper>
  )
}

export default EditProjectForm
